import React from 'react'

export const Order = () => {
  return (
    <div className="order">
      Order
    </div>
  )
}


