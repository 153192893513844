import React from 'react'
import { Email } from './Email'
import { DataItems } from '../components/DataItems';
// import './Contact.css';
export const Contact = () => {
  return (
    <div className="contact">
      <Email/>
      </div>
  )
}